











































import {Vue, Component, Prop} from 'vue-property-decorator';
import {Ax} from "@/utils";
import i18n from "@/services/i18n";
import {Org} from "@/modules/budget/staffing-table/types";

@Component({
    computed: {
        i18n() {
            return i18n
        }
    }
})
export default class InstructionView extends Vue
{

    @Prop({
        type: String,
        required: true,
    })
    public title!: String;

     getInstruction() {
        Ax(
            {
                url: '/api/dict/npa/instruction',
                method: 'GET',
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data as BlobPart]));
                const link = document.createElement('a');
                link.href = url;
                const fileName = `${this.$t('modules.app.additional_npa.mode_info.mode_instruction_sgp')}`

                link.setAttribute('download', fileName + '.pdf');
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                console.error(error.key);
            }
        );
    }
}
